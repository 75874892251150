<template>
    <!-- google shop integration code -->
    <div class="right-menu shipping-right">
        <div class="db-blue-section pt-0">

            <subHeader pageName="GOOGLE SHOP"/>

            <tutorialsAction />

            <div class="db-container">
                <div class="mianBlurBox pt-0">
                    <div class="seoDashboardTabsBlock mt-0">
                        <div class="tabsBtnRow googleShop">
                            <button @click.prevent="changeTab('connection')" :class="currentTab == 'connection' ? 'tab_active' : ''">Connection</button>
                            <button @click.prevent="changeTab('synced_products')" :class="currentTab == 'synced_products' ? 'tab_active' : ''">Synced Product</button>
                            <button @click.prevent="changeTab('unsynced_products')" :class="currentTab == 'unsynced_products' ? 'tab_active' : ''">Unsynced Product</button>
                        </div>
                        <div class="tabsDataBox">

                            <!-- connection block starts here -->
                            <div class="seoFeaturesBox" v-show="currentTab == 'connection'" v-if="shopConnectionData">
                                <div class="featuresOptionBox">
                                    <div class="foptionTxt">
                                        <p>Merchant Center ID :</p>
                                    </div>
                                    <div class="foptionCheckBox">
                                        <div class="foptionTxt">
                                            <p>{{ shopConnectionData.merchant_center_id }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="featuresOptionBox">
                                    <div class="foptionTxt">
                                        <p>Merchant Center Name :</p>
                                    </div>
                                    <div class="foptionCheckBox">
                                        <div class="foptionTxt">
                                            <p>{{ shopConnectionData.merchant_center_name }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="featuresOptionBox">
                                    <div class="foptionTxt">
                                        <p>Auto-Sync :</p>
                                    </div>
                                    <div class="foptionCheckBox">
                                        <p>OFF</p>
                                        <label class="switch">
                                        <input 
                                            v-model="shopConnectionData.auto_sync" 
                                            type="checkbox"
                                            @click.stop="shopAutoSyncUpdateHandle('connection-table')"
                                        >
                                        <span class="slider"></span>
                                        </label>
                                        <p>ON</p>
                                    </div>
                                </div>

                                <div class="dataSaveBtn" style="text-align: center; margin-top: 0px;">
                                    <button @click.prevent="disconnectShopHandle()">Disconnect Your Shop</button>
                                </div>

                            </div>
                            <div class="masterTools" v-show="currentTab == 'connection'" v-else>
                                <div class="dataSaveBtn" style="text-align: center; margin-top: 0px;">
                                    <button @click.prevent="initiateGoogleMerchantConnectionProcess()">Connect Your Shop</button>
                                </div>
                            </div>
                            <!-- connection block ends here -->

                            <!-- synced products tab block starts here -->
                            <div class="integration" v-show="currentTab == 'synced_products'">

                                <div class="tableMainWrapBox" style="position: relative;">
                                    <div class="tableScrollWrap">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div class="ctmCheckWrap">
                                                            <label class="ctmTableCheckContainer">
                                                                <input 
                                                                    type="checkbox" 
                                                                    @click="toggleIsCheckedAll('synced')"
                                                                    :checked="syncedCheckedItems.length != 0 && syncedCheckedItems.length == syncedProductsList.length"
                                                                />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmCheckWrap">
                                                            <div class="ctmTableImgWrap">
                                                                <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th class="ctmPr-3">
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Price">Price</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Stock">Stock</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Type">Type</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Synced">Synced</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="productsLoader">
                                                <tr v-for="i in 5" :key="i">
                                                    <td>
                                                        <div class="ctmCheckWrap">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="17"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="ctmCheckWrap">
                                                            <div class="ctmDataTableImgBox">
                                                                <vue-skeleton-loader
                                                                    type="circle"
                                                                    :width="36"
                                                                    :height="36"
                                                                    animation="fade"
                                                                />
                                                            </div> 
                                                        </div>
                                                    </td>
                                                    <td class="mainTableTitle">
                                                        <div class="editFieldTitle">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="100"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="350"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="200"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="200"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="70"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="70"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>

                                                <tr v-if="syncedProductsList && syncedProductsList.length == 0">
                                                    <td colspan="9" style="text-align:center">
                                                        <p class="mb-0">
                                                            No Products Available
                                                        </p>
                                                    </td>
                                                </tr>

                                                <tr v-for="(product, index) in syncedProductsList" :key="index">

                                                    <!-- product check block starts here -->
                                                    <td>
                                                        <div class="ctmCheckWrap">
                                                            <label class="ctmTableCheckContainer" @click.prevent="checkedItemHandle('synced',product.id)">
                                                                <input type="checkbox" v-model="syncedCheckedItems" :value="product.id"/>
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <!-- product check block ends here -->

                                                    <!-- image block starts here -->
                                                    <td>
                                                        <div class="ctmCheckWrap" >
                                                            <div class="ctmDataTableImgBox">
                                                                <img 
                                                                    :src=" product.image ? base_image_url + 
                                                                    settings.store_id + '/product/' + product.image : '/images/no_image.png'" 
                                                                    @error="handleImageError"
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <!-- checkbox & image block ends here -->

                                                    <!-- product name block starts here -->
                                                    <td>
                                                        <div class="tableBodyCtmTxt" >
                                                            <p>{{ product.name }}</p>
                                                        </div>
                                                    </td>
                                                    <!-- product name block ends here -->

                                                    <!-- product price block starts here -->
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <el-popover placement="left" title="Price Details" width="300" id="priceRangeTable" trigger="hover">
                                                                <el-table :data=" getVariants(product) ? getVariants(product) : ''">
                                                                    <el-table-column property="name" label="Name"></el-table-column>
                                                                    <el-table-column property="price" label="Price"></el-table-column>
                                                                </el-table>
                                                                <el-button slot="reference">{{getPriceRange(product)}}</el-button>
                                                            </el-popover>
                                                        </div>
                                                    </td>
                                                    <!-- product price block ends here -->

                                                    <!-- product stock block starts here -->
                                                    <td>
                                                        <div class="productStockWrap multiStockBox" :class=" getStock(product) ? getStock(product).isLow ? '' : 'moreStock' : ''" >
                                                            <el-popover placement="right" title="Stock Details" width="300" trigger="hover">
                                                                <el-table :data=" getVariants(product) ? getVariants(product) : ''">
                                                                    <el-table-column property="name" label="Name"></el-table-column>
                                                                    <el-table-column property="qty" label="Stock"></el-table-column>
                                                                </el-table>
                                                                <el-button slot="reference">
                                                                    {{ getStock(product) ? getStock(product).stock : "" }}
                                                                </el-button>
                                                            </el-popover>
                                                        </div>
                                                    </td>
                                                    <!-- product stock block ends here -->

                                                    <!-- product product type block starts here -->
                                                    <td>
                                                        <div class="tableBodyCtmTxt" >
                                                            <p>{{ product.product_type ? product.product_type : '--' }}</p>
                                                        </div>
                                                    </td>
                                                    <!-- product product type block ends here -->

                                                    <!-- product synced block starts here -->
                                                    <td>
                                                        <div class="ctmDataTableActionBtn">
                                                            <button class="delFunction synched">
                                                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <!-- product synced block ends here -->

                                                    <!-- action block starts here -->
                                                    <td>
                                                        <div class="ctmDataTableActionBtn" v-if="isSyncingUnsyncingItem('synced',product.id) && productUnsyncLoader">
                                                            <loaderBtn />
                                                        </div>
                                                        <div class="ctmDataTableActionBtn" v-else>
                                                            <button class="delFunction sync" @click.prevent="singleProductSyncUnsyncHandle('synced',product.id)">
                                                                <i class="fa fa-stop-circle-o" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <!-- action block ends here -->

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!-- bottom action bar starts here -->
                                <div class="row product-bottom-bar m-0" v-if="syncedCheckedItems.length > 1">
                                    <div class="col-md-6 model-footer-main1">
                                        <div class="bottom-bar">
                                            <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="syncedCheckedItems.length != syncedProductsList.length">
                                                <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                                <p>SELECT ALL</p>
                                            </a>
                                            <a href="#." class="model-footer-1" @click="clearSelection('synced')">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                                </svg>
                                                <p>CLEAR SELECTION</p>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-md-6 model-footer-main1 product-row-reverse">
                                        <div class="bottom-bar">
                                            <a href="#." class="model-footer-1 ml-4" @click="bulkProductSyncHandle('synced')">
                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                                <p>Unsync</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- bottom action bar ends here -->

                            </div>
                            <!-- synced products tab block ends here -->

                            <!-- unsynced products tab block starts here -->
                            <div class="integration" v-show="currentTab == 'unsynced_products'">

                                <div class="tableMainWrapBox" style="position: relative;">
                                    <div class="tableScrollWrap">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div class="ctmCheckWrap">
                                                            <label class="ctmTableCheckContainer">
                                                                <input 
                                                                    type="checkbox" 
                                                                    @click="toggleIsCheckedAll('unsynced')"
                                                                    :checked="checkedItems.length != 0 && checkedItems.length == unsyncedProductsList.length"
                                                                />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmCheckWrap">
                                                            <div class="ctmTableImgWrap">
                                                                <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th class="ctmPr-3">
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Price">Price</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Stock">Stock</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Type">Type</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Synced">Synced</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="productsLoader">
                                                <tr v-for="i in 5" :key="i">
                                                    <td>
                                                        <div class="ctmCheckWrap">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="17"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="ctmCheckWrap">
                                                            <div class="ctmDataTableImgBox">
                                                                <vue-skeleton-loader
                                                                    type="circle"
                                                                    :width="36"
                                                                    :height="36"
                                                                    animation="fade"
                                                                />
                                                            </div> 
                                                        </div>
                                                    </td>
                                                    <td class="mainTableTitle">
                                                        <div class="editFieldTitle">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="220"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="200"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="200"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="200"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="70"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <vue-skeleton-loader
                                                                type="rect"
                                                                :width="70"
                                                                :height="20"
                                                                animation="fade"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>

                                                <tr v-if="unsyncedProductsList && unsyncedProductsList.length == 0">
                                                    <td colspan="9" style="text-align:center">
                                                        <p class="mb-0">
                                                            No Products Available
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr v-for="(product, index) in unsyncedProductsList" :key="index">

                                                    <!-- product check block starts here -->
                                                    <td>
                                                        <div class="ctmCheckWrap">
                                                            <label class="ctmTableCheckContainer" @click.prevent="checkedItemHandle('unsynced',product.id)">
                                                                <input type="checkbox" v-model="checkedItems" :value="product.id"/>
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <!-- product check block ends here -->

                                                    <!-- image block starts here -->
                                                    <td>
                                                        <div class="ctmCheckWrap" >
                                                            <div class="ctmDataTableImgBox">
                                                                <img 
                                                                    :src=" product.image ? base_image_url + 
                                                                    settings.store_id + '/product/' + product.image : '/images/no_image.png'" 
                                                                    @error="handleImageError"
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <!-- checkbox & image block ends here -->

                                                    <!-- product name block starts here -->
                                                    <td>
                                                        <div class="tableBodyCtmTxt" >
                                                            <p>{{ product.name }}</p>
                                                        </div>
                                                    </td>
                                                    <!-- product name block ends here -->

                                                    <!-- product price block starts here -->
                                                    <td>
                                                        <div class="tableBodyCtmTxt">
                                                            <el-popover placement="left" title="Price Details" width="300" id="priceRangeTable" trigger="hover">
                                                                <el-table :data=" getVariants(product) ? getVariants(product) : ''">
                                                                <el-table-column property="name" label="Name"></el-table-column>
                                                                <el-table-column property="price" label="Price"></el-table-column>
                                                                </el-table>
                                                                <el-button slot="reference">{{getPriceRange(product)}}</el-button>
                                                            </el-popover>
                                                        </div>
                                                    </td>
                                                    <!-- product price block ends here -->

                                                    <!-- product stock block starts here -->
                                                    <td>
                                                        <div class="productStockWrap multiStockBox" :class=" getStock(product) ? getStock(product).isLow ? '' : 'moreStock' : ''" >
                                                            <el-popover placement="right" title="Stock Details" width="300" trigger="hover">
                                                                <el-table :data=" getVariants(product) ? getVariants(product) : ''">
                                                                <el-table-column property="name" label="Name"></el-table-column>
                                                                <el-table-column property="qty" label="Stock"></el-table-column>
                                                                </el-table>
                                                                <el-button slot="reference">{{ getStock(product) ? getStock(product).stock : "" }}</el-button>
                                                            </el-popover>
                                                        </div>
                                                    </td>
                                                    <!-- product stock block ends here -->

                                                    <!-- product product type block starts here -->
                                                    <td>
                                                        <div class="tableBodyCtmTxt" >
                                                            <p>{{ product.product_type ? product.product_type : '--' }}</p>
                                                        </div>
                                                    </td>
                                                    <!-- product product type block ends here -->

                                                    <!-- product synced block starts here -->
                                                    <td>
                                                        <div class="ctmDataTableActionBtn">
                                                            <button class="delFunction">
                                                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <!-- product synced block ends here -->

                                                    <!-- action block starts here -->
                                                    <td>
                                                        <div class="ctmDataTableActionBtn" v-if="isSyncingUnsyncingItem('unsynced',product.id) && productSyncLoader">
                                                            <loaderBtn />
                                                        </div>
                                                        <div class="ctmDataTableActionBtn" v-else>
                                                            <button class="delFunction sync" @click.prevent="singleProductSyncUnsyncHandle('unsynced',product.id)">
                                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <!-- action block ends here -->

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!-- bottom action bar starts here -->
                                <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                                    <div class="col-md-6 model-footer-main1">
                                        <div class="bottom-bar">
                                            <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != unsyncedProductsList.length">
                                                <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                                <p>SELECT ALL</p>
                                            </a>
                                            <a href="#." class="model-footer-1" @click="clearSelection('unsynced')">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                                </svg>
                                                <p>CLEAR SELECTION</p>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-md-6 model-footer-main1 product-row-reverse">
                                        <div class="bottom-bar">
                                            <a href="#." class="model-footer-1 ml-4" @click="bulkProductSyncHandle('unsynced')">
                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                                <p>Sync</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- bottom action bar ends here -->

                            </div>
                            <!-- unsynced products tab block ends here -->

                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- google merchant center modal starts here -->
        <div class="modalBg passwordResetPopup" :class="googleMerchantCenterForm.isOpen ? 'modal-active': ''">
            <div class="modalLayers" @click.prevent="abandonGoogleMerchantCenterConnection('abandon')"></div>
            <div class="modalBody">
                <div class="oderSlideBox productSideBox" style="position:relative;top:15px;right:0;width:100%;overflow: hidden !important;">
                    <ValidationObserver ref="googleMerchantCenterFormObserver">
                        <form @submit.prevent="validate('merchantCenterForm')">
                            <div class="row">
                                <div class="col-md-12">
                                    <ValidationProvider name="merchant center id" vid="password" :rules="{required:true}" v-slot="{ errors }">
                                        <div class="tableFromFieldBox">
                                            <label for="popupPassword">Merchant Center ID <span>*</span></label>
                                            <div class="tableFromFieldItem passwordBtnIconPd">
                                                <input 
                                                    type="text" 
                                                    name="popupPassword" 
                                                    id="popupPassword" 
                                                    placeholder="Merchant center id"
                                                    autocomplete="true"
                                                    v-model="googleMerchantCenterForm.merchantCenterId"
                                                >
                                                <span class="text-danger" >{{ errors[0] }}</span>
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-md-12">
                                    <div class="formCustomerBtn">
                                        <loaderBtn v-if="googleMerchantCenterForm.loader"/>
                                        <button v-else type="submit">Connect</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
                <div class="modalCloseIcon">
                    <button @click.prevent="abandonGoogleMerchantCenterConnection('abandon')">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                            <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <!-- google merchant center modal ends here -->
        
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import {MessageBox, Message,Loading} from 'element-ui';
import _ from 'lodash';

export default {
    name: 'googleShop',
    metaInfo() {
        return {
            title: 'Marketing | Google Shop | YeetCommerce',
        };
    },
    data(){
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            currentTab: 'connection',

            googleMerchantCenterForm:{
                merchantCenterId:'',
                refreshToken:'',
                accessToken:'',
                isOpen:false,
                loader:false,
            },
            shopConnectionData:null,
            productsList:[],
            syncedProductsList:[],
            unsyncedProductsList:[],
            productsLoader:false,

            checkedItems:[],

            syncedCheckedItems:[],

            syncingItems:[],
            productSyncLoader:false,

            unsyncingItems:[],
            productUnsyncLoader:false,

        }
    },
    components:{
        subHeader,
        tutorialsAction,
        loaderBtn
    },
    computed:{
        ...mapGetters({
            settings: 'settings_module/settings',
        }),
    },
    watch:{

    },
    methods:{
        changeTab(tab){

            if(tab == 'connection' || tab == 'synced_products' || tab == 'unsynced_products'){

                if(!this.shopConnectionData){

                    if(tab == 'synced_products'){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: "Connect a shop to view synced product.",
                        });

                    }

                    if(tab == 'unsynced_products'){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: "Connect a shop to view unsynced product.",
                        });

                    }                    


                }else{

                    this.currentTab = tab;

                }
                
            }else{

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: "The feature is currently under development.",
                });

                return 

            }

        },

        initiateGoogleMerchantConnectionProcess(){

            MessageBox.confirm(
                'Do you really want to initiate the shop connection process?',
                'Warning',
                {
                    type: 'warning',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                }
            ).then(async ()=>{

                let scope = "https://www.googleapis.com/auth/content";
                let redirectUri = window.location.href;
                let accessType = 'offline';
                let clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID_FINAL;

                let url = 'https://accounts.google.com/o/oauth2/v2/auth?'+
                `client_id=${clientId}&scope=${scope}&access_type=${accessType}&include_granted_scopes=true&prompt=consent&redirect_uri=${redirectUri}&response_type=code`

                window.location = url;

            }).catch(()=>{})

        },

        validate(form){

            if(form == 'merchantCenterForm'){

                this.$refs.googleMerchantCenterFormObserver.validate().then(success => {
                    
                    if(!success){

                        const errors = Object.entries(this.$refs.googleMerchantCenterFormObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);
                        
                        this.$refs.googleMerchantCenterFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center"});

                    }else{

                        this.saveConnectionDetails();
                    }
                    
                });

            }

        },
        //google merchant center form methods start
        async abandonGoogleMerchantCenterConnection(action){

            if(action == 'abandon'){
                
                if(!this.googleMerchantCenterForm.loader){

                    this.googleMerchantCenterForm.isOpen = false;

                    MessageBox.confirm(
                        'Do you really want to abandon the shop connection process?',
                        'Warning',
                        {
                            type: 'warning',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'Cancel',
                        }
                    ).then(async ()=>{

                        let formData = new FormData();

                        formData.append('access_token',this.googleMerchantCenterForm.accessToken);

                        try{
                            let res = await this.$axios.post('/google/auth/token/revoke',formData);
                            if(res.data.status_code == '1701'){

                                this.$notify({
                                    type: 'success',
                                    title: 'Success',
                                    message: res.data.message,
                                });

                                this.resetGoogleMerchantCenterForm();

                            }
                        }catch(error){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response ? error.response.data.message : error.message,
                            });

                        }

                    }).catch(()=>{

                        this.googleMerchantCenterForm.isOpen = true;

                    });

                }

            }
            
            if(action == 'revoke'){

                if(!this.googleMerchantCenterForm.loader){

                    let formData = new FormData();

                    formData.append('access_token',this.googleMerchantCenterForm.accessToken);

                    try{
                        let res = await this.$axios.post('/google/auth/token/revoke',formData);
                        if(res.data.status_code == '1701'){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });

                            this.resetGoogleMerchantCenterForm();

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }

                }

            }

        },
        resetGoogleMerchantCenterForm(){

            this.googleMerchantCenterForm = {
                merchantCenterId:'',
                refreshToken:'',
                accessToken:'',
                isOpen:false,
                loader:false,
            }

        },
        async saveConnectionDetails(){

            let formData = new FormData();

            formData.append('merchant_center_id',this.googleMerchantCenterForm.merchantCenterId);
            formData.append('refresh_token',this.googleMerchantCenterForm.refreshToken);

            this.googleMerchantCenterForm.loader = true;
            try{
                let res = await this.$axios.post('/google/shop/connection/add',formData)
                if(res.data.status_code == '1705'){

                    this.resetGoogleMerchantCenterForm();

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.loadShopConnection();

                    this.shopAutoSyncUpdateHandle('connection');
                    
                }
            }catch(error){

                this.googleMerchantCenterForm.loader = false;

                this.abandonGoogleMerchantCenterConnection('revoke');

                if(error.response){

                    if(error.response.data.error.includes('already exists') ||
                    error.response.data.error.includes("already associated")){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }

        },
        async disconnectShopHandle(){

            MessageBox.confirm(
                'Do you really want to disconnect your google shop?',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'warning',
                }
            ).then(async ()=>{

                let loader = Loading.service({
                    text: "The Google shop connection is being revoked. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.delete(`google/shop/connection/remove/${this.shopConnectionData.id}`)
                    if(res.data.status_code == '1707'){
                        
                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        this.loadShopConnection();

                    }
                }catch(error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });

                }finally{

                    loader.close();

                }

            }).catch(()=>{})

        },
        async shopAutoSyncUpdateHandle(action){

            if(action == 'connection'){

                MessageBox.confirm(
                    'Do you want to enable the auto-synchronization capability?',
                    'Warning',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        type: 'warning',
                    }
                ).then(async ()=>{

                    let formData = new FormData();

                    formData.append('sync', 1 );

                    let loader = Loading.service({
                        text: "The auto synchronization is being updated. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post('/google/shop/sync/update',formData);
                        if(res.data.status_code == "1600"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Auto synchronization feature has been successfully enabled.The products listed in the store are syncing.",
                            });

                        }else if(res.data.status_code == "1600"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Auto synchronization feature has been successfully disabled.",
                            });

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        loader.close();

                        this.loadShopConnection();

                    }
                    
                }).catch(() => {})


            }else if(action == "connection-table"){

                MessageBox.confirm(
                    this.shopConnectionData.auto_sync ? 'Do you want to disable the auto-synchronization feature?' :
                    'Do you want to enable the auto-synchronization feature?',
                    'Warning',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        type: 'warning',
                    }
                ).then(async ()=>{

                    let formData = new FormData();

                    formData.append('sync', this.shopConnectionData.auto_sync ? 1 : 0);

                    let loader = Loading.service({
                        text: "The auto synchronization is being updated. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post('/google/shop/sync/update',formData);
                        if(res.data.status_code == "1600"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Auto synchronization feature has been successfully enabled.The products listed in the store are syncing.",
                            });

                        }else if(res.data.status_code == "1601"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Auto synchronization feature has been successfully disabled.",
                            });

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        loader.close();

                        this.loadShopConnection();

                    }

                }).catch(() => {

                    this.shopConnectionData.auto_sync = !this.shopConnectionData.auto_sync;

                })

            }

        },
        //google merchant center form methods end

        async loadShopConnection(){

            let loader = Loading.service({
                text: "Fetching Google shop connection. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.get('google/shop/connection/get');
                if(res.data.status_code == '1706'){

                    this.shopConnectionData = res.data.connection;

                    if(this.shopConnectionData){

                        this.loadProducts();

                    }
                    
                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('has been lost')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                loader.close();
                
            }

        },
        async loadShopProducts(){

            this.productsLoader = true;

            let url =`https://content.googleapis.com/content/v2.1/${this.shopConnectionData.merchant_center_id}/products`

            fetch(url,{
                method:'GET',
                headers: {
                    'Authorization': `Bearer ${this.shopConnectionData.access_token}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }).then((response) =>{

                return response.json();

            }).then(data =>{

                if(data.error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: "Something went wrong while retrieving products. Please try later.",
                    });

                }else{

                    this.productsList = data.resources;

                }

                this.productsLoader = false;

            });

        },
        async loadProducts(){

            this.productsLoader = true;
            try{
                let res = await this.$axios.get('/products/all');
                if(res.data.status_code == "1007"){

                    this.syncedProductsList = res.data.products.filter((product) =>{

                        if(product.synced_with_google_shop == 1){

                            return product;

                        }

                    });

                    this.unsyncedProductsList = res.data.products.filter((product) =>{

                        if(product.synced_with_google_shop == 0){

                            return product;

                        }

                    });

                }

            }catch(error){

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                this.productsLoader = false;

            }

        },

        getStock(product){

            if(product.variants){

                if(product.variants.length > 1){

                    let minStock = product.variants[0].qty;
                    let maxStock = product.variants[0].qty;

                    let isLowCheck = false;

                    product.variants.forEach((variant) => {

                        if(variant.qty > maxStock){

                            maxStock = variant.qty;

                        }
                        
                        if(variant.qty < minStock){

                            minStock = variant.qty;

                        }

                        if(variant.qty <= 10){

                            isLowCheck = true;

                        }

                    });

                    let obj = {
                        isLow: isLowCheck,
                        stock: minStock + " - " + maxStock,
                        min: minStock,
                        max: maxStock,
                    };

                    return obj;

                }else if(product.variants.length == 1){

                    let isLowCheck = false;

                    if(product.variants[0].qty <= 10){

                        isLowCheck = true;

                    }

                    let obj = {
                        isLow: isLowCheck,
                        stock: product.variants[0].qty,
                    };

                    return obj;

                }

            }else{

                return false;

            }

        },
        getVariants(product){

            if(!product.isOpen && product.variants){

                let variants = product.variants.map((variant) => {

                    let obj = {
                        name: variant.name,
                        qty: variant.qty,
                        price: variant.price ? this.settings.currency_symbol + " " + variant.price.toLocaleString("ur-PK", {
                            // style: "currency",
                            // currency: this.settings.currency_unit,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) : 0,

                    };

                    return obj;

                });

                return variants;

            }else if(product.isOpen){

                let variants = [];

                this.totalVariant.forEach((variant) => {

                    let obj = {
                        name: variant.name,
                        qty: variant.qty,
                        price: variant.price ? this.settings.currency_symbol + " " + parseFloat(variant.price).toLocaleString("ur-PK", {
                            // style: "currency",
                            // currency: this.settings.currency_unit,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) : 0,

                    };

                    variants.push(obj);

                })

                return variants;

            }
        },
        getPriceRange(product){

            if(!product.isOpen && product.variants.length > 1){

                let maxPrice = product.variants[0].price;
                let minPrice = product.variants[0].price;

                product.variants.forEach((variant) => {

                    if(variant.price != null && (variant.price > maxPrice)){

                        maxPrice = variant.price;

                    }
                    if(variant.price != null && (variant.price < minPrice)){

                        minPrice = variant.price;

                    }

                });

                return (
                    this.settings.currency_symbol + " " + minPrice.toLocaleString("ur-PK", {
                        // style: "currency",
                        // currency: this.settings.currency_unit,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }) +
                    " - " +
                    this.settings.currency_symbol + " " + maxPrice.toLocaleString("ur-PK", {
                        // style: "currency",
                        // currency: this.settings.currency_unit,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })
                );

            }else if(!product.isOpen && product.variants.length == 1){

                return product.variants[0].price.toLocaleString("ur-PK", {
                    style: "currency",
                    currency: this.settings.currency_unit,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });

            }else if(product.isOpen && this.totalVariant.length > 0 && this.totalVariant[0].price){

                let maxPrice = this.totalVariant[0].price;
                let minPrice = this.totalVariant[0].price;
                
                this.totalVariant.forEach((variant) => {

                    if(variant.price != null && (variant.price > maxPrice)){

                        maxPrice = variant.price;

                    }
                    if(variant.price != null && (variant.price < minPrice)){

                        minPrice = variant.price;

                    }

                });
                
                return (
                    this.settings.currency_symbol + " " + minPrice.toLocaleString("ur-PK", {
                        // style: "currency",
                        // currency: this.settings.currency_unit,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }) +
                    " - " +
                    this.settings.currency_symbol + " " + maxPrice.toLocaleString("ur-PK", {
                        // style: "currency",
                        // currency: this.settings.currency_unit,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })
                );

            }

        },
        checkedItemHandle(form,productId){

            if(form == 'synced'){

                let index = this.syncedCheckedItems.findIndex(item => item == productId);

                if(index != -1){

                    this.syncedCheckedItems.splice(index,1);

                }else{

                    this.syncedCheckedItems.push(productId);

                }

            }else if(form == 'unsynced'){

                let index = this.checkedItems.findIndex(item => item == productId);

                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(productId);

                }

            }

        },
        toggleIsCheckedAll(form){

            if(form == 'synced'){

                if((this.syncedCheckedItems.length == this.syncedProductsList.length) ||
                (this.syncedCheckedItems.length == this.syncedProductsList.length)){

                    this.syncedCheckedItems = [];

                }else if(this.syncedCheckedItems.length < this.syncedProductsList.length){

                    this.syncedCheckedItems = [];

                    this.syncedProductsList.forEach((product) => {

                        this.syncedCheckedItems.push(product.id);

                    });

                }

            }else if(form == 'unsynced'){

                if((this.checkedItems.length == this.unsyncedProductsList.length) ||
                (this.checkedItems.length == this.unsyncedProductsList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.unsyncedProductsList.length){

                    this.checkedItems = [];

                    this.unsyncedProductsList.forEach((product) => {

                        this.checkedItems.push(product.id);

                    });

                }

            }

        },
        clearSelection(form){

            if(form == 'synced'){

                this.syncedCheckedItems = [];

            }else if(form == 'unsynced'){

                this.checkedItems = [];

            }
            
        },

        singleProductSyncUnsyncHandle(form,productId){

            MessageBox.confirm(
                form == 'synced' ? "Are you sure you want to unsync the product from google merchant center?" :
                "Are you sure you want to sync the product from google merchant center?", 
                "Warning",
                {
                    type: "warning",
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                }
            ).then(async () => {

                let formData = new FormData();

                if(form == 'synced'){

                    this.unsyncingItems.push(productId);
                    this.productUnsyncLoader = true;

                    formData.append('products',JSON.stringify(this.unsyncingItems));

                    try{
                        let res = await this.$axios.post('google/products/unsync',formData);
                        if(res.data.status_code == "1709"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });

                            this.loadShopConnection();

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        this.unsyncingItems = [];
                        this.productUnsyncLoader = false;

                    }

                }else if(form == 'unsynced'){

                    this.syncingItems.push(productId);
                    this.productSyncLoader = true;

                    formData.append('products',JSON.stringify(this.syncingItems));

                    try{
                        let res = await this.$axios.post('google/products/sync',formData);
                        if(res.data.status_code == "1708"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });

                            this.loadShopConnection();

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        this.syncingItems = [];
                        this.productSyncLoader = false;

                    }

                }

            }).catch(() => {})

        },
        bulkProductSyncHandle(form){

            MessageBox.confirm(
                form == 'synced' ? "Are you sure you want to unsync the product from google merchant center?" :
                "Are you sure you want to sync the products with google merchant center?", 
                "Warning",
                {
                    type: "warning",
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                }
            ).then(async () => {

                let formData = new FormData();

                if(form == 'synced'){

                    this.unsyncingItems = _.cloneDeep(this.syncedCheckedItems);
                    this.productUnsyncLoader = true;

                    formData.append('products',JSON.stringify(this.unsyncingItems));

                    try{
                        let res = await this.$axios.post('google/products/unsync',formData);
                        if(res.data.status_code == "1709"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });

                            this.loadShopConnection();

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        this.unsyncingItems = [];
                        this.productUnsyncLoader = false;

                    }

                }else if(form == 'unsynced'){

                    this.syncingItems = _.cloneDeep(this.checkedItems);
                    this.productSyncLoader = true;

                    formData.append('products',JSON.stringify(this.syncingItems));

                    try{
                        let res = await this.$axios.post('google/products/sync',formData);
                        if(res.data.status_code == "1708"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });

                            this.loadShopConnection();

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        this.syncingItems = [];
                        this.productSyncLoader = false;

                    }

                }

            }).catch(() => {})

        },

        isSyncingUnsyncingItem(form,productId){

            if(form == 'synced'){

                let find = this.unsyncingItems.find(x => x == productId);

                if(find){

                    return true;

                }else{

                    return false;

                }

            }else if(form == 'unsynced'){

                let find = this.syncingItems.find(x => x == productId);

                if(find){

                    return true;

                }else{

                    return false;

                }

            }

        },

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png';

        },

    },
    mounted(){

    },
    async beforeMount(){

        if(this.$route.query.code && (this.$route.query.scope && this.$route.query.scope.includes("auth/content"))){

            let formData = new FormData();

            formData.append('authorization_code',this.$route.query.code);

            let loader = Loading.service({
                text: "We're authenticating your connection details. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/google/auth/token/generate',formData);
                if(res.data.status_code == '1700'){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.googleMerchantCenterForm = {
                        merchantCenterId:'',
                        refreshToken: res.data.token_details.refresh_token,
                        accessToken: res.data.token_details.access_token,
                        isOpen:true,
                        loader:false,
                    }

                }

            }catch(error){

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                loader.close();

                this.$router.replace({'query': null});

            }

        }else{

            this.loadShopConnection();

        }

    },
}
</script>

<style>

</style>